import styled from 'styled-components';
import AxeResults from './Results/AxeResults';
import AppInfo from './AppInfo';
import { MdSpeed, MdWeb, MdOutlineFindInPage, MdExpandMore, MdSearch, MdRemoveCircleOutline } from 'react-icons/md';
import Logout from './Logout';

const Component = styled.aside`
	background: var(--darkest);
	color: white;
	height: 100vh;
	padding: 1rem;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	&::-webkit-scrollbar {
		display: none;
	}
`;

const AddressForm = styled.form`
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
`;

const Field = styled.div`
	display: grid;
	grid-template-columns: 6rem 1fr;
	gap: 0.75rem;
	align-items: center;
`;

const URL = styled.input`
	all: unset;
	padding: 0.5rem 0.75rem;
	flex: 1 0 auto;
	border: 0;
	background: var(--bs-dark);
	box-shadow: 0 0.5rem 0.75rem #0003;
	border-radius: 0.5rem;
	font-size: 1rem;

	&:disabled {
		pointer-events: none;
		opacity: 0.5;
	}
`;

const EngineIcon = styled(MdSpeed)`
	color: var(--bs-info);
	margin-right: 0.5rem;
	font-size: 1.5rem;
`;

const ScanIcon = styled(MdOutlineFindInPage)`
	color: violet;
	margin-right: 0.5rem;
	font-size: 1.5rem;
`;

const URLIcon = styled(MdWeb)`
	color: royalBlue;
	margin-right: 0.5rem;
	font-size: 1.5rem;
`;

const SelectWrapper = styled.div`
	position: relative;
	display: grid;
`;

const SelectArrow = styled(MdExpandMore)`
	position: absolute;
	right: 0.5rem;
	top: 50%;
	transform: translateY(-50%);
	font-size: 1.5rem;
	pointer-events: none;
	color: #fff6;
`;

const Select = styled.select`
	all: unset;
	padding: 0.5rem 0.75rem;
	border: 0;
	background: var(--bs-dark);
	box-shadow: 0 0.5rem 0.75rem #0003;
	border-radius: 0.5rem;
	font-size: 1rem;
	cursor: pointer;

	&:disabled {
		pointer-events: none;
		opacity: 0.5;
	}
`;

const Name = styled.label``;

const Button = styled.button`
	all: unset;
	transition: 0.2s;
	border: 2px solid;
	border-radius: 0.5rem;
	height: 3rem;
	transition: 0.2s;
	display: grid;
	grid-template-columns: repeat(2, auto);
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	margin: 0.5rem 0 0;

	&:disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	svg {
		width: 1.5rem;
		height: 1.5rem;
	}
`;

const Submit = styled(Button)`
	color: var(--bs-info);

	&:hover,
	&:focus-visible {
		color: white;
		background: var(--bs-info);
		border-color: var(--bs-info);
	}
`;

const Clear = styled(Button)`
	color: white;

	&:hover,
	&:focus-visible {
		color: var(--bs-dark);
		background: white;
		border-color: white;
	}
`;

const Sidebar = ({ params, setParams, handleSubmit, report, error, handleClear, selectedElement, setSelectedElement, isValidUrl }) => {
	// Handlers
	const handleChange = e => {
		const { name, value } = e.target;
		setParams(params => ({ ...params, [name]: value }));
	};

	return (
		<Component>
			<AppInfo />
			<AddressForm onSubmit={handleSubmit}>
				<Field>
					<Name>
						<EngineIcon />
						Engine:
					</Name>
					<SelectWrapper>
						<Select value={params.engine} name='engine' onChange={e => handleChange(e)} disabled={report}>
							<option value='axe-core'>axe-core</option>
						</Select>
						<SelectArrow />
					</SelectWrapper>
				</Field>
				<Field>
					<Name>
						<ScanIcon />
						Scan:
					</Name>
					<SelectWrapper>
						<Select value={params.scan} name='scan' onChange={e => handleChange(e)} disabled={report}>
							<option value='single-page'>Single Page</option>
							<option value='full-site' disabled>
								Full Site
							</option>
						</Select>
						<SelectArrow />
					</SelectWrapper>
				</Field>
				<Field>
					<Name>
						<URLIcon />
						URL:
					</Name>
					<URL type='url' value={params.url} name='url' onChange={e => handleChange(e)} required disabled={report} />
				</Field>
				{!report && !error ? (
					<Submit type='submit' disabled={!isValidUrl}>
						Start Scan <MdSearch />
					</Submit>
				) : (
					<Clear onClick={handleClear}>
						Clear Results <MdRemoveCircleOutline />
					</Clear>
				)}
			</AddressForm>
			{report && report.engine === 'axe-core' && <AxeResults report={report} selectedElement={selectedElement} setSelectedElement={setSelectedElement} />}
			<Logout />
		</Component>
	);
};

export default Sidebar;
