import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import Button from 'components/shared/Button';
import { Link } from 'react-router-dom';

const Component = styled.div`
	text-align: center;
	padding: 2rem;
	color: var(--bs-danger);
`;

export default function NotFound() {
	return (
		<Component>
			<Helmet>
				<title>404 | Page not found | Axe Accessibility Tool</title>
			</Helmet>
			<h1>404</h1>
			<p>Oops, this page could not be found.</p>
			<Link to='/'>
				<Button>Go Home</Button>
			</Link>
		</Component>
	);
}
