import { useEffect } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './utils/ProtectedRoute';
import Login from './components/pages/Login';
import ReactGA from 'react-ga4';
import { SWRConfig } from 'swr';
import { fetcher } from 'utils/Fetch';

// On route changes
const usePageChange = () => {
	// Initialize GA
	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
	}, []);
};

const App = () => {
	usePageChange();
	return (
		<HelmetProvider>
			<Helmet>
				<title>Axe Accessibility Tool</title>
			</Helmet>
			<SWRConfig value={{ fetcher: url => fetcher(url), shouldRetryOnError: false }}>
				<Routes>
					<Route path='*' element={<ProtectedRoute />} />
					<Route path='/login' element={<Login />} />
				</Routes>
			</SWRConfig>
		</HelmetProvider>
	);
};

export default App;
