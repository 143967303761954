import styled from 'styled-components';
import { Heading } from './Components';
import { Accordion, useAccordionButton } from 'react-bootstrap';
import { MdExpandMore } from 'react-icons/md';
import { FiExternalLink } from 'react-icons/fi';
import { tidyCapitalize } from 'utils/Tidy';

const Component = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
`;

const Items = styled.div`
	display: grid;
	align-items: start;
	gap: 0.75rem;
`;

const Item = styled.div`
	background: var(--bs-dark);
	border-radius: 0.5rem;
	padding: 1rem;
	font-size: 0.9rem;
	transition: 0.1s;
	cursor: pointer;
	display: grid;
	align-items: start;
	gap: 0.25rem;
	position: relative;

	&:hover,
	&:focus-visible {
		background: var(--midDark);
	}
`;

const Field = styled.div`
	display: grid;
	grid-template-columns: 5rem 1fr;
	gap: 1rem;
	padding-right: 1rem;
`;

const Name = styled.div`
	color: lightGrey;
	text-align: right;
`;

const Value = styled.div``;

const Elements = styled.div`
	font-size: 0.75rem;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 0.5rem;
`;

const Element = styled.div`
	max-width: 100%;
	background: #fff1;
	line-height: 1;
	border-radius: 999px;
	padding: 0.5rem 0.75rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	transition: 0.2s;

	&:hover,
	&:focus-visible,
	&.selected {
		background: var(--bs-info);
		box-shadow: 0 0.25rem 0.5rem #0003;
	}
`;

const Impact = styled.div`
	color: ${({ $impact }) => impactColor[$impact]};
`;

const ExpandIcon = styled(MdExpandMore)`
	position: absolute;
	bottom: 0.5rem;
	right: 0.5rem;
	font-size: 1.5rem;
	color: #fff6;
	pointer-events: none;
`;

const InfoLink = styled.a`
	position: absolute;
	top: 0.25rem;
	right: 0.75rem;
	font-size: 1rem;
	color: #fff6;
	transition: 0.2s;
`;

const impactColor = {
	critical: 'Crimson',
	serious: 'OrangeRed',
	moderate: 'DarkOrange',
	minor: 'Orange'
};

const ItemAccordion = ({ children, eventKey }) => {
	// Handlers
	const handleShowDetails = useAccordionButton(eventKey);
	return <Item onClick={() => handleShowDetails()}>{children}</Item>;
};

const Criteria = ({ tags }) => {
	if (tags.includes('wcag2aa')) {
		return <div>WCAG 2.1 AA</div>;
	}

	if (tags.includes('wcag2a')) {
		return <div>WCAG 2.1 A</div>;
	}
};

const AxeResults = ({ report, selectedElement, setSelectedElement }) => {
	// Props
	const { errors } = report;
	const errorCount = errors.reduce((total, current) => (current.nodes.length > 0 ? (total = total + current.nodes.length) : total), 0);

	// Sorting
	const sortByImpact = (a, b) => {
		const impactMap = {
			critical: 1,
			serious: 2,
			moderate: 3,
			minor: 4
		};

		return impactMap[a.impact] - impactMap[b.impact];
	};

	return (
		<Component>
			<Heading icon={'error'} text={`Errors (${errorCount})`} />
			<Items>
				{errors
					.sort((a, b) => sortByImpact(a, b))
					.map((error, i) => (
						<Accordion key={i}>
							<ItemAccordion eventKey={i + 1}>
								<InfoLink href={error.helpUrl} target='_blank' rel='noopener noreferrer'>
									<FiExternalLink />
								</InfoLink>
								<ExpandIcon />
								<Field>
									<Name>Description:</Name>
									<Value>
										<strong>{error.help}</strong>
									</Value>
								</Field>
								<Field>
									<Name>Criteria:</Name>
									<Value>
										<Criteria tags={error.tags} />
									</Value>
								</Field>
								<Field>
									<Name>Impact:</Name>
									<Value>
										<Value>
											<Impact $impactimpact={error.impact}>{tidyCapitalize(error.impact)}</Impact>
										</Value>
									</Value>
								</Field>
								<Field>
									<Name>Count:</Name>
									<Value>{error.nodes.length}</Value>
								</Field>
								<Accordion.Collapse eventKey={i + 1}>
									<Field>
										<Name>Elements:</Name>
										<Elements>
											{error.nodes.map((item, i) => (
												<Element
													key={i}
													onClick={e => {
														e.stopPropagation(e);
														setSelectedElement({ selector: item.target[0], message: item.any[0].message, ...item.any[0].data });
													}}
													className={selectedElement?.selector === item.target[0] && 'selected'}>
													{item.target[0]}
												</Element>
											))}
										</Elements>
									</Field>
								</Accordion.Collapse>
							</ItemAccordion>
						</Accordion>
					))}
			</Items>
		</Component>
	);
};

export default AxeResults;
