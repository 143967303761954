import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import BoundingBox from './BoundingBox';
import logo from 'img/app/appfolio_logo_wh.svg';
import { MdOutlineError } from 'react-icons/md';

const Component = styled.main`
	position: relative;
	background: var(--bs-lightest);
	overflow: auto;
`;

const Logo = styled.img`
	width: 10rem;
	height: 10rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	filter: drop-shadow(0 0 10rem);
`;

const FrameWrapper = styled.div`
	position: relative;
	height: ${({ $height }) => $height}px;
`;

const Frame = styled.iframe`
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
`;

const Error = styled.div`
	color: var(--bs-danger);
	text-align: center;
	padding: 2rem;
`;

const SiteFrame = ({ html, selectedElement, error }) => {
	// State
	const [bounds, setBounds] = useState(null);
	const [frameDimensions, setFrameDimensions] = useState({});

	// Hooks
	const frameRef = useRef();
	const mainRef = useRef();
	const wrapperRef = useRef();

	// Handlers
	const computeFrameSpecs = async () => {
		const document = frameRef.current.contentDocument;
		const body = document.querySelector('body');
		const wrapper = body.querySelector('.dmLayoutWrapper');

		const height = wrapper.scrollHeight;
		const width = wrapper.scrollWidth;
		setFrameDimensions({ height, width });

		mainRef.current.addEventListener('scroll', () => {
			const { scrollTop, scrollHeight, offsetHeight } = mainRef.current;
			if (scrollTop === scrollHeight - offsetHeight) {
				if (scrollHeight < wrapper.scrollHeight) {
					setFrameDimensions(prev => ({ ...prev, height: wrapper.scrollHeight }));
				}
			}
		});
	};

	const handleFrameClick = e => {
		if (e.target === wrapperRef.current) {
			setBounds(null);
		}
	};

	// Effects
	useEffect(() => {
		if (selectedElement) {
			setBounds(null);
			const selector = selectedElement.selector.replace(/((#)(\d))/g, '$2\\3$3 ');
			const element = frameRef.current.contentDocument.querySelector(selector);
			if (selector && element) {
				element.scrollIntoView({ block: 'center' });
				setTimeout(() => {
					const rectangle = element.getBoundingClientRect().toJSON();
					if (Object.values(rectangle).every(value => value !== 0)) {
						setBounds(rectangle);
					} else {
						alert("This element is not visible in the page's current state. It may be lazy loaded and require you to scroll past it to become visible.");
					}
				}, 100);
			} else {
				alert('This element is not visible on the current page.');
			}
		}
	}, [selectedElement]);

	useEffect(() => {
		if (!html) {
			setBounds(null);
			setFrameDimensions({});
		}
	}, [html]);

	return (
		<Component ref={mainRef}>
			{error ? (
				<Error>
					<h2>
						<MdOutlineError /> Error
					</h2>
					{error}
				</Error>
			) : html ? (
				<FrameWrapper ref={wrapperRef} $height={frameDimensions.height} onClick={e => handleFrameClick(e)}>
					{bounds && <BoundingBox bounds={bounds} info={selectedElement} frameDimensions={frameDimensions} />}
					<Frame ref={frameRef} height={frameDimensions.height} srcDoc={html} title='Site Frame' onLoad={() => computeFrameSpecs()} sandbox='allow-scripts allow-same-origin' />
				</FrameWrapper>
			) : (
				<Logo src={logo} alt='AppFolio Logo' />
			)}
		</Component>
	);
};

export default SiteFrame;
