import logo from 'img/app/appfolio_logo_wh.svg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Component = styled.div`
	text-align: center;
	display: grid;
	gap: 1rem;
	justify-content: center;
	padding: 1rem 0;
`;

const Logo = styled.img`
	width: 70px;
	height: 70px;
`;

const Name = styled.h4`
	margin: 0;
	font-weight: bold;
`;

const AppInfo = () => {
	return (
		<Component>
			<Link to='/'>
				<Logo src={logo} alt='AppFolio Logo' width='70' height='70' />
			</Link>
			<Name>AXE Accessibility Tool</Name>
		</Component>
	);
};

export default AppInfo;
