import styled from 'styled-components';
import { useAccordionButton } from 'react-bootstrap';
import { MdWarningAmber, MdOutlineErrorOutline } from 'react-icons/md';

const WarningIcon = styled(MdWarningAmber)`
	font-size: 1.5rem;
	color: var(--bs-warning);
`;

const ErrorIcon = styled(MdOutlineErrorOutline)`
	font-size: 1.5rem;
	color: var(--bs-danger);
`;

const HeadingComponent = styled.h3`
	font-size: 1.25rem;
	display: flex;
	gap: 0.5rem;
	margin: 0;
`;

export const Heading = ({ icon, text }) => {
	return (
		<HeadingComponent>
			{icon === 'warning' && <WarningIcon />}
			{icon === 'error' && <ErrorIcon />}
			{text}
		</HeadingComponent>
	);
};

export const Issues = styled.div``;

export const Issue = styled.div`
	background: var(--bs-dark);
	box-shadow: ${({ $selected }) => (!$selected ? '0 0.5rem 0.75rem #0003' : `0 3px 0 0 var(--bs-info)`)};
	border-radius: 0.5rem;
	padding: 1rem;
	margin: 1rem;
	line-height: 1.75;
	font-size: 0.9rem;
	transition: 0.1s;
	cursor: pointer;

	&:first-of-type {
		margin-top: 0;
	}

	&:hover,
	&:focus-visible {
		background: var(--bs-lightest);
	}
`;

export const Field = styled.div`
	display: grid;
	grid-template-columns: 4rem minmax(0, 1fr);
	gap: 1rem;
`;

export const Key = styled.div`
	text-align: right;
	color: var(--Grey);
`;
export const Value = styled.div`
	word-break: all;

	p {
		margin: 0;
	}

	a {
		color: white;
	}
`;

export const Selector = styled(Value)`
	color: var(--Purple);
`;

export const Button = styled.button`
	all: unset;
	border: 1px solid;
	border-radius: 999px;
	padding: 0.5rem 1rem;
	font-size: 0.8rem;
	line-height: 1;
	margin: 0.5rem 0;
	transition: 0.1s;

	&:hover,
	&:focus-visible {
		background: white;
		color: black;
	}
`;

export const Details = styled.div``;

export const Content = styled.code``;

export const DetailsButton = ({ children, eventKey }) => {
	// Handlers
	const handleShowDetails = useAccordionButton(eventKey, () => {});
	return <Button onClick={() => handleShowDetails()}>{children}</Button>;
};
