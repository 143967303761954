import styled from 'styled-components';

const Component = styled.button`
	all: unset;
	color: var(--bs-dark);
	border: 2px solid;
	width: 16rem;
	height: 3rem;
	line-height: 3rem;
	border-radius: 0.5rem;
	transition: 0.1s;
	display: inline-block;

	&:hover,
	&:focus {
		background: var(--bs-dark);
		color: white;
	}
`;

const Button = ({ children, ...props }) => {
	return <Component {...props}>{children}</Component>;
};

export default Button;
