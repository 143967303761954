import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MdOutlineErrorOutline } from 'react-icons/md';
import ContrastChecker from './ContrastChecker';

const Component = styled.div`
	position: absolute;
	z-index: 10;
	width: ${({ $width }) => $width}px;
	height: ${({ $height }) => $height}px;
	top: ${({ $top }) => $top}px;
	left: ${({ $left }) => $left}px;
	color: var(--bs-info);
	border: 0.25rem solid;
	box-shadow: 0 0 1rem 0;
	transition: 0.1s;
	overflow: visible;
`;

const Panel = styled.div`
	position: relative;
	transform: translateX(${({ $x }) => $x}) translateY(${({ $y }) => $y});
	background: var(--bs-dark);
	color: white;
	font-size: 0.9rem;
	border-radius: 0.5rem;
	width: 20rem;
	overflow: hidden;
	box-shadow: 0 10px 20px #0003;
`;

const Heading = styled.div`
	border-bottom: 1px solid #fff2;
	padding-bottom: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
	padding: 0.75rem;
`;

const Title = styled.h4`
	font-size: 1rem;
	margin: 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const ErrorIcon = styled(MdOutlineErrorOutline)`
	font-size: 1.5rem;
	color: var(--bs-danger);
	flex: 0 0 auto;
`;

const Message = styled.div`
	padding: 0.75rem;
`;

const Field = styled.dl`
	display: flex;
	gap: 0.5rem;
	margin: 0;
`;

const Name = styled.dd`
	margin: 0;
	color: lightGrey;
`;

const Value = styled.dt`
	margin: 0;
	font-weight: normal;
`;

const BoundingBox = ({ bounds, info, frameDimensions }) => {
	// State
	const [panelTransform, setPanelTransform] = useState({ x: 0, y: `calc(${bounds.height}px + 0.5rem)` });
	const { selector, contrastRatio, expectedContrastRatio, message, fgColor, bgColor } = info;
	const requiredContrast = parseFloat(expectedContrastRatio?.replace(':1', '')).toFixed(2);

	// Effects
	useEffect(() => {
		// If panel is too close to left of screen
		if (bounds.right >= frameDimensions.width - 300) {
			setPanelTransform(prev => ({ ...prev, x: `calc(-100% + ${bounds.width}px)` }));
		}

		// If panel is too close to bottom of screen
		if (bounds.bottom >= frameDimensions.height - 300) {
			setPanelTransform(prev => ({ ...prev, y: 'calc(-100% - 1rem)' }));
		}
	}, [bounds, frameDimensions]);

	return (
		<Component $width={bounds.width} $height={bounds.height} $top={bounds.top} $left={bounds.left}>
			<Panel $x={panelTransform.x} $y={panelTransform.y}>
				<Heading>
					<Title>{selector}</Title>
					<ErrorIcon />
				</Heading>
				{contrastRatio ? (
					<>
						<Message>
							<Field>
								<Name>Contrast:</Name>
								<Value>{contrastRatio.toFixed(2)}</Value>
							</Field>
							<Field>
								<Name>Required:</Name>
								<Value>{requiredContrast}</Value>
							</Field>
						</Message>
						<ContrastChecker selector={selector} requiredContrast={requiredContrast} foreground={fgColor} background={bgColor} />
					</>
				) : (
					<Message>
						<Field>
							<Name>Error:</Name>
							<Value>{message}</Value>
						</Field>
					</Message>
				)}
			</Panel>
		</Component>
	);
};

export default BoundingBox;
