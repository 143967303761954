import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getLuminance } from 'utils/Colors';
import { MdCheckCircleOutline, MdErrorOutline } from 'react-icons/md';
const convert = require('color-convert');

const Component = styled.div`
	background: var(--bs-dark);
	padding: 0.75rem;
	display: grid;
	gap: 0.25rem;
`;

const Swatch = styled.div`
	height: 1.25rem;
	width: 1.25rem;
	border-radius: 50%;
	background: ${({ $color }) => $color};
	cursor: pointer;
	transition: 0.1s;

	&:hover,
	&:focus-visible {
		box-shadow: inset 0 0 0 2px #fff6;
	}
`;

const Field = styled.dl`
	display: flex;
	gap: 0.5rem;
	margin: 0;
	justify-content: space-between;
	align-items: center;
`;

const Name = styled.dd`
	margin: 0;
	color: lightGrey;
`;

const Value = styled.dt`
	margin: 0;
	font-weight: normal;
	display: flex;
	justify-content: end;
	align-items: center;
	gap: 0.75rem;
`;

const Contrast = styled.div`
	display: flex;
	gap: 0.75rem;
	align-items: center;
	position: relative;
	left: 0.1rem;
`;

const PassIcon = styled(MdCheckCircleOutline)`
	color: var(--bs-success);
	font-size: 1.5rem;
`;

const FailIcon = styled(MdErrorOutline)`
	color: var(--bs-danger);
	font-size: 1.5rem;
`;

const Reset = styled.button`
	all: unset;
	opacity: 0.35;
	text-decoration: 1px dotted underline;
`;

const CustomSlider = styled.input`
	-webkit-appearance: none;
	width: 100%;
	border-radius: 999px;
	margin: 0.5rem 0;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		height: 1.5rem;
		width: 1.5rem;
		background: white;
		border-radius: 999px;
		box-shadow: 0 2px 5px #0009;
		border: 1px solid #eee;
		transform: translateY(-3px);
	}

	&::-webkit-slider-runnable-track {
		-webkit-appearance: none;
		height: 1rem;
		background: ${({ $color }) => `linear-gradient(to right, hsl(${$color[0]}, ${$color[1]}%, 0%), hsl(${$color[0]}, ${$color[1]}%, 50%), hsl(${$color[0]}, ${$color[1]}%, 100%))`};
		border-radius: 999px;
	}
`;

const ContrastChecker = ({ selector, requiredContrast, foreground, background }) => {
	const [colors, setColors] = useState({ foreground, background });
	const [calculatedContrast, setCalculatedContrast] = useState(0);
	const [contrastPass, setContrastPass] = useState(false);
	const [showFSlider, setShowFSlider] = useState(false);
	const [showBSlider, setShowBSlider] = useState(false);

	// Handlers
	const handleColorChange = (e, hsl) => {
		const { name, value } = e.target;
		const [h, s] = hsl;
		const newColor = [h, s, parseInt(value)];
		setColors({ ...colors, [name]: `#${convert.hsl.hex(newColor).toLowerCase()}` });
	};

	const handleReset = () => {
		setColors({ foreground, background });
	};

	// Effects
	useEffect(() => {
		setColors({ foreground, background });
	}, [selector, foreground, background]);

	useEffect(() => {
		// Get RGB & luminance of foreground
		const fRgb = convert.hex.rgb(colors.foreground);
		const fLuminance = getLuminance(fRgb);

		// Get RGB & luminance of background
		const bRgb = convert.hex.rgb(colors.background);
		const bLuminance = getLuminance(bRgb);

		// Calculate & set contrast ratio
		const ratio = Math.floor(((Math.max(fLuminance, bLuminance) + 0.05) / (Math.min(fLuminance, bLuminance) + 0.05)) * 100) / 100;
		setCalculatedContrast(ratio.toFixed(2));
	}, [colors]);

	useEffect(() => {
		parseFloat(calculatedContrast) >= parseFloat(requiredContrast) ? setContrastPass(true) : setContrastPass(false);
	}, [requiredContrast, calculatedContrast]);

	return (
		<Component>
			<Field>
				<Name>Calculated Contrast:</Name>
				<Contrast>
					{calculatedContrast} {contrastPass ? <PassIcon /> : <FailIcon />}
				</Contrast>
			</Field>
			<Field>
				<Name>Foreground:</Name>
				<Value>
					{colors.foreground}
					<Swatch $color={colors.foreground} onClick={() => setShowFSlider(!showFSlider)} max={100} min={0} />
				</Value>
			</Field>
			{showFSlider && <CustomSlider $color={convert.hex.hsl(colors.foreground)} type='range' name='foreground' value={convert.hex.hsl(colors.foreground)[2]} onChange={e => handleColorChange(e, convert.hex.hsl(colors.foreground))} />}
			<Field>
				<Name>Background:</Name>
				<Value>
					{colors.background}
					<Swatch $color={colors.background} onClick={() => setShowBSlider(!showBSlider)} max={100} min={0} />
				</Value>
			</Field>
			{showBSlider && <CustomSlider $color={convert.hex.hsl(colors.background)} type='range' name='background' value={convert.hex.hsl(colors.background)[2]} onChange={e => handleColorChange(e, convert.hex.hsl(colors.background))} />}
			<Reset onClick={() => handleReset()}>Reset</Reset>
		</Component>
	);
};

export default ContrastChecker;
