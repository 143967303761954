import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

// Tidy URLs
export function tidyUrl(url) {
	return url && url.replace('http://', '').replace('https://', '').replace(/\/$/g, '');
}

// Tidy dates
export function tidyDate(date) {
	return date && dayjs(date).format('MM/DD/YYYY');
}

export function tidyDatePicker(date) {
	return date && dayjs(date).format('YYYY-MM-DD');
}

export function tidyUTCDate(date) {
	return date && dayjs(date).utc().format('MM/DD/YYYY');
}

export function tidyTime(date) {
	return date && dayjs(date).format('h:mm A');
}

export function tidyDateTime(date) {
	return date && dayjs(date).format('MMMM D YYYY @ h:mm A');
}

// Tidy text
export function tidyCapitalize(text) {
	return text
		.toLowerCase()
		.split(/ |_/)
		.map(word => word[0].toUpperCase() + word.slice(1))
		.join(' ');
}

export function tidyUnderscores(text) {
	return text.toLowerCase()[0].toUpperCase() + text.slice(1).split('_').join(' ');
}

export function addUnderscores(text) {
	return text
		.toLowerCase()
		.replace(/[^a-zA-Z0-9]/g, ' ')
		.replace(/\s\s+/g, ' ')
		.split(' ')
		.join('_');
}

export function addDashes(text) {
	return text
		.toLowerCase()
		.replace(/[^a-zA-Z0-9]/g, ' ')
		.replace(/\s\s+/g, ' ')
		.split(' ')
		.join('-');
}

export function tidyMarkdown(text) {
	const textArray = text.split(' (');
	return `[${textArray[0]}](${textArray[1]}`;
}

export function tidyDashes(text) {
	const withSpaces = text.toLowerCase().split('-').join(' ');
	return withSpaces[0].toUpperCase() + withSpaces.slice(1);
}

export function tidyParagraphs(text) {
	return text.split('\n').map(paragraph => `<p>${paragraph}</p>`);
}

// Tidy email addresses
export function tidyUserEmail(email) {
	return email
		.split('@')[0]
		.replace('.', ' ')
		.split(' ')
		.map(word => word[0].toUpperCase() + word.slice(1))
		.join(' ');
}

// Tidy database strings
export function tidyDatabase(url) {
	const urlObject = new URL(url);
	if (urlObject.hostname === 'api.appfoliotest.com') {
		const db = urlObject.pathname.replace('/api/v1/listings/', '');
		return `${db}.appfolio.com`;
	} else {
		return urlObject.hostname;
	}
}

export function tidyPropertyGroup(url) {
	return new URL(url).searchParams.get('property_lists[]');
}

// Tidy file sizes
export function tidyFileSize(bytes) {
	const kilo = 1024;
	const sizes = ['Bytes', 'KB', 'MB'];
	const i = Math.floor(Math.log(bytes) / Math.log(kilo));
	return `${parseFloat((bytes / Math.pow(kilo, i)).toFixed(2))} ${sizes[i]}`;
}
