import styled from 'styled-components';
import { useLogin } from 'utils/UserContext';
import { googleLogout } from '@react-oauth/google';
import ProfileImage from 'components/shared/ProfileImage';
import { fetcher } from 'utils/Fetch';

const Component = styled.div`
	display: grid;
	grid-auto-flow: column;
	gap: 1.5rem;
	padding: 0 1rem;
	align-items: center;
	justify-content: start;
	margin-top: auto;
`;

const Info = styled.div`
	font-size: 0.9rem;
`;

const SignOut = styled.button`
	all: unset;
	color: var(--bs-info);
	text-decoration: underline dotted;

	&:hover,
	&:focus {
		text-decoration-style: solid;
	}
`;

const Logout = () => {
	// Hooks
	const { user, setUser } = useLogin();
	const { email, imageUrl, name } = user;

	// Handlers
	const handleLogout = async () => {
		googleLogout();
		await fetcher('/auth/signout');
		setUser(null);
	};

	return (
		<Component>
			<ProfileImage src={imageUrl} alt={name} width='50' />
			<Info>
				<div>Signed in as:</div>
				<div>
					<strong>{email}</strong>
				</div>
				<SignOut onClick={() => handleLogout()}>Sign out</SignOut>
			</Info>
		</Component>
	);
};

export default Logout;
