import { useLocation, Navigate, Routes, Route } from 'react-router-dom';
import { useLogin } from './UserContext';
import NewReport from 'components/Report/Report';
import Error from 'components/pages/Error';
import NotFound from 'components/pages/NotFound';

const ProtectedRoute = () => {
	const { user } = useLogin();
	const location = useLocation();

	return user ? (
		<Routes>
			<Route index element={<Navigate replace to='/report' />} />
			<Route path='/report' element={<NewReport />} />
			<Route path='/error' element={<Error />} />
			<Route path='*' element={<NotFound />} />
		</Routes>
	) : (
		<Navigate to='login' replace state={{ from: location.pathname + location.search }} />
	);
};

export default ProtectedRoute;
