import { createRoot } from 'react-dom/client';
import { UserProvider } from './utils/UserContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import './styles/App.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Router>
		<CookiesProvider>
			<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
				<UserProvider>
					<App />
				</UserProvider>
			</GoogleOAuthProvider>
		</CookiesProvider>
	</Router>
);
